<template>
    <div class="programma" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <div class="containerProgramma">
            <div class="cntLuoghi animate__animated animate__fadeIn" v-for="(luogo, index) in luoghi" :key="index">
                <img class="imgLuogo" :src="luogo.src" />
                <div class="cntLuogo">
                    <div class="evento">{{ luogo.evento }}</div>
                    <div class="luogo">{{ luogo.luogo }}</div>
                    <div class="luogo ora">{{ luogo.ore }}</div>
                    <div class="indirizzo">{{ luogo.indirizzo }}</div>
                    <v-menu offset-y v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mappa" color="transparent" v-bind="attrs" v-on="on">
                                <div>Mappa</div>
                                <v-icon dark>
                                    mdi-google-maps
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(mappa, index) in luogo.mappe" :key="index" @click="openMap(mappa.nav)">
                                <v-list-item-title>{{ mappa.app }}</v-list-item-title>
                                <img :src="mappa.icon" class="iconMaps" />
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn class="mappa" color="transparent" v-else @click="openMap(luogo.mappe[0].nav)">
                        <div>Mappa</div>
                        <v-icon dark>
                            mdi-google-maps
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <img class="imgFiore" src="../assets/green-flower.png" />
    </div>
</template>

<script>
import { isAndroid } from "mobile-device-detect";
export default {
    head: {
        title: {
            inner: "Programma",
        }
    },

    mounted() {
        this.luoghi[0].mappe = this.mappeChiesa;
        this.luoghi[1].mappe = this.mappeLocation;
    },

    data() {
        return {
            luoghi: [
                {
                    evento: "La Cerimonia si svolgerà presso:",
                    src: require("../assets/chiesa-sant-angelo-perugia.jpg"),
                    luogo: "Chiesa di San Michele Arcangelo",
                    ore: "ore 10:30",
                    indirizzo: "Via del Tempio, 06123 Perugia PG",
                    mappe: []
                },
                {
                    evento: "Saremo lieti di festeggiare con voi presso:",
                    src: require("../assets/tenuta-montenero.jpg"),
                    luogo: "Tenuta Montenero",
                    ore: "ore 14:00",
                    indirizzo: "Via Tiberina, 06053 Deruta PG",
                    mappe: []
                }
            ]
        };
    },

    computed: {
        mappeChiesa() {
            if (isAndroid) {
                return [
                    {
                        nav: "https://www.google.com/maps?daddr=chiesa+san+michele+arcangelo+perugia",
                        app: "Google",
                        icon: require("../assets/google-maps-icon.png")
                    },
                    {
                        nav: "https://waze.com/ul?q=tempio+san+michele+arcangelo+comune+perugia",
                        app: "Waze",
                        icon: require("../assets/waze-maps-icon.png")
                    }
                ];
            } else {
                return [
                    {
                        nav: "https://www.google.com/maps?daddr=chiesa+san+michele+arcangelo+perugia",
                        app: "Google",
                        icon: require("../assets/google-maps-icon.png")
                    },
                    {
                        nav: "http://maps.apple.com/maps?daddr=chiesa+san+michele+arcangelo+perugia",
                        app: "Mappe (iOS)",
                        icon: require("../assets/ios-maps-icon.png")
                    },
                    {
                        nav: "https://waze.com/ul?q=tempio+san+michele+arcangelo+comune+perugia",
                        app: "Waze",
                        icon: require("../assets/waze-maps-icon.png")
                    }
                ];
            }
        },

        mappeLocation() {
            if (isAndroid) {
                return [
                    {
                        nav: "https://www.google.com/maps?daddr=tenuta+montenero",
                        app: "Google Maps",
                        icon: require("../assets/google-maps-icon.png")
                    },
                    {
                        nav: "https://waze.com/ul?q=tenuta+montenero",
                        app: "Waze",
                        icon: require("../assets/waze-maps-icon.png")
                    }
                ];
            } else {
                return [
                    {
                        nav: "https://www.google.com/maps?daddr=tenuta+montenero",
                        app: "Google Maps",
                        icon: require("../assets/google-maps-icon.png")
                    },
                    {
                        nav: "https://maps.apple.com/maps?daddr=42.99985338413305,12.428971282545795",
                        app: "Mappe (iOS)",
                        icon: require("../assets/ios-maps-icon.png")
                    },
                    {
                        nav: "https://waze.com/ul?q=tenuta+montenero",
                        app: "Waze",
                        icon: require("../assets/waze-maps-icon.png")
                    }
                ];
            }
        }
    },

    methods: {
        openMap(mappa) {
            window.open(mappa, "_blank");
        }
    }
};
</script>

<style lang="scss">
@import "../app";

.programma {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    display: flex;
    flex-direction: column;
    .containerProgramma {
        display: flex;
        justify-content: space-evenly;
        margin-top: 80px;
        .cntLuoghi {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: 20px 20px 70px 20px;
            animation-duration: 3s;
            .imgLuogo {
                width: 80%;
                border-radius: 13px;
            }
            .cntLuogo {
                display: flex;
                flex-direction: column;
                align-items: center;
                .evento {
                    font-size: 25px;
                    font-family: "Alex Brush", cursive !important;
                    margin-top: 15px;
                }
                .luogo {
                    font-size: 40px;
                    font-family: "Alex Brush", cursive !important;
                    font-weight: bold;
                }
                .luogo.ora {
                    font-size: 30px;
                }
                .indirizzo {
                    font-size: 20px;
                }
                .mappa {
                    display: flex;
                    align-items: center;
                    color: #000;
                    border: 1px solid #000 !important;
                    margin-top: 12px;
                    box-shadow: unset !important;
                }
            }
        }
    }
}

.programma.phone {
    .containerProgramma {
        flex-wrap: wrap;
        .cntLuoghi {
            .cntLuogo {
                .luogo {
                    font-size: 30px;
                }
                .luogo.ora {
                    font-size: 20px;
                }
                .indirizzo {
                    font-size: 15px;
                }
            }
        }
    }
}

.v-menu__content {
    width: 180px;
    .v-list {
        padding: unset !important;
        .v-list-item {
            display: grid;
            grid-template-columns: 70% 65%;
            border-bottom: 1px solid #000;
            min-height: unset !important;
            .iconMaps {
                width: 55%;
            }
        }
    }
}
</style>
